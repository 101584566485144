<template>
  <grid-container-fi
    class="teaser-big-image-element-fi"
    :class="{ 'teaser-big-image-element-fi--slider-element': isSliderElement }"
    :container="false"
  >
    <div class="fi-col-span-12 fi-col-start-1">
      <div
        class="teaser-big-image-element-fi__container"
        :class="[
          { 'teaser-big-image-element-fi__container--dark': background === 'dark' },
          { 'teaser-big-image-element-fi__container--light': background === 'light' },
          { 'teaser-big-image-element-fi__container--white': background === 'white' },
          { 'teaser-big-image-element-fi__container--big-image': isBigImage },
          { 'teaser-big-image-element-fi__container--image-pos-right': isImagePosRight },
        ]"
      >
        <div
          class="teaser-big-image-element-fi__column teaser-big-image-element-fi__image-container"
        >
          <div
            v-if="isEditMode && isVideo(fields)"
            class="video-player-fi__overlay"
          >
            <div class="video-player-fi__play-button">
              <icon-fi icon="play" />
            </div>
          </div>

          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="!isSliderElement && isEditMode && fields.image"
            class="teaser-big-image-element-fi__image--editor"
            v-html="fields.image.editable"
          />
          <!-- eslint-enable vue/no-v-html -->

          <div v-if="isSliderElement && isEditMode && fields.image">
            <img :src="fields.image?.value.src" />
          </div>

          <div
            v-else-if="!isEditMode && isImageSrc && !isVideo(fields)"
            class="teaser-big-image-element-fi__image"
          >
            <cloud-image-fi
              :src="fields.image?.value.src || image?.src"
              :alt="fields.image?.value.alt || image?.alt"
              :params="imageParams"
              full-height
            />
          </div>

          <div
            v-else-if="!isEditMode && isImageSrc && isVideo(fields)"
            class="teaser-big-image-element-fi__video"
          >
            <video-player-fi
              class="teaser-big-image-element-fi__video-player"
              :fields="fields"
              :pause-video="pauseVideo"
              preview-params="func=crop&org_if_sml=0"
            />
          </div>

          <div
            v-if="innovationAwards?.length"
            class="teaser-big-image-element-fi__innovation-awards"
          >
            <div
              v-for="(award, index) in innovationAwards"
              :key="index"
              class="teaser-big-image-element-fi__award"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="isEditMode && !isSliderElement && award.fields.image"
                class="teaser-big-image-element-fi__image"
                v-html="award.fields.image.editable"
              />
              <!-- eslint-enable vue/no-v-html -->
              <div
                v-else-if="!isEditMode && award.fields.image?.value?.src"
                class="teaser-big-image-element-fi__award-img"
              >
                <cloud-image-fi
                  :src="award.fields.image.value.src"
                  :alt="award.fields.image.value.alt"
                  params="func=crop&org_if_sml=0"
                  full-height
                />
              </div>
            </div>
          </div>
        </div>

        <div
          ref="contentContainer"
          class="teaser-big-image-element-fi__column teaser-big-image-element-fi__details-container"
        >
          <div
            ref="content"
            class="teaser-big-image-element-fi__details"
          >
            <template v-if="isEditMode && fields.content">
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="!isSliderElement && fields.content.editable"
                ref="rteContent"
                :class="{
                  'teaser-big-image-element-fi__content richtext-fi': true,
                  'richtext-fi--light': background === 'brand' || background === 'dark',
                }"
                v-html="fields.content.editable"
              />
              <div
                v-else
                ref="rteContent"
                :class="{
                  'teaser-big-image-element-fi__content richtext-fi': true,
                  'richtext-fi--light': background === 'brand' || background === 'dark',
                }"
                v-html="fields.content.value"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>
            <template v-else>
              <!-- eslint-disable vue/no-v-html -->
              <richtext-fi
                ref="rteContent"
                :class="{
                  'teaser-big-image-element-fi__content': true,
                  'richtext-fi--light': background === 'brand' || background === 'dark',
                }"
                :is-theme-light="background === 'brand' || background === 'dark'"
                :html-content="fields.content ? fields.content : null"
              >
                <slot name="content" />
              </richtext-fi>
              <!-- eslint-enable vue/no-v-html -->
            </template>
          </div>
        </div>
      </div>
    </div>
  </grid-container-fi>
</template>

<script>
import { IconFi, RichtextFi } from 'atoms';
import CloudImageFi from 'components/atoms/cloud-image-fi/CloudImageFi';
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import VideoPlayerFi from 'components/video-player-fi/VideoPlayerFi';
import breakpointMixin from 'mixins/breakpointMixin';
import videoMixin from 'mixins/videoMixin';
import { throttle } from 'throttle-debounce';
import { mapGetters } from 'vuex';

import smoothScroll from '@/utils/smoothScroll';

export default {
  name: 'TeaserBigImageElementFi',
  components: {
    IconFi,
    GridContainerFi,
    RichtextFi,
    CloudImageFi,
    VideoPlayerFi,
  },
  mixins: [breakpointMixin, videoMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    isSliderElement: {
      type: Boolean,
      default: false,
    },
    pauseVideo: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      default: null,
    },
    imagePosRight: {
      type: Boolean,
      default: null,
    },
    bgColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      wrapperClassName: 'teaser-big-image-element-fi__truncate-wrapper',
      throttledResizeListener: null,
    };
  },
  computed: {
    ...mapGetters({
      windowDimensions: 'layout/getWindowDimensions',
    }),
    isImageSrc() {
      if (this.image?.src) {
        return true;
      }

      return this.fields.image?.value?.src;
    },
    isBigImage() {
      return Boolean(this.fields?.bigImageSize?.value);
    },
    isImagePosRight() {
      if (this.imagePosRight) {
        return this.imagePosRight;
      }

      return Boolean(this.fields?.imagePosRight?.value);
    },
    innovationAwards() {
      if (this.fields?.teaserBigImageInnovationAwards?.length) {
        return this.fields.teaserBigImageInnovationAwards;
      }
      return [];
    },
    background() {
      if (this.fields.darkBackground?.value) {
        return 'dark';
      }

      if (this.fields.lightBackground?.value) {
        return 'light';
      }

      if (this.fields.whiteBackground?.value) {
        return 'white';
      }

      if (this.bgColor) {
        return this.bgColor;
      }

      return 'brand';
    },
    imageParams() {
      if (this.isMD) {
        return 'func=crop&org_if_sml=0';
      }

      return 'aspect_ratio=16:9&org_if_sml=0';
    },
  },
  watch: {
    windowDimensions: {
      deep: true,
      handler() {
        this.throttledResizeListener?.();
      },
    },
  },
  async mounted() {
    if (!this.isEditMode) {
      await this.prepareContent();

      if (this.$refs.rteContent) {
        smoothScroll(this.$refs.rteContent.$el);
      }

      if (!IS_SSR) {
        this.throttledResizeListener = throttle(800, () => this.truncateContent());
      }

      await this.$nextTick();
      await this.truncateContent();
    }
  },
  methods: {
    async prepareContent() {
      const targetContentElement =
        this.isEditMode && !this.isSliderElement
          ? this.$refs.rteContent.querySelector('.scWebEditInput')
          : this.$refs.rteContent.$el.childNodes[0];
      const contentElements = this.isEditMode
        ? targetContentElement?.childNodes
        : this.$refs.rteContent.$el.childNodes[0].childNodes;
      const firstElement = contentElements?.[0];
      if (!firstElement) {
        return;
      }

      const wrapper = document.createElement('div');
      wrapper.classList.add(this.wrapperClassName);
      targetContentElement.insertBefore(wrapper, firstElement);

      await this.$nextTick();

      const truncateWrapper = targetContentElement.querySelector(`.${this.wrapperClassName}`);

      Array.from(contentElements)
        .splice(1)
        .forEach((el) => {
          if (el.classList?.contains('button-fi')) {
            return;
          }

          truncateWrapper.appendChild(el);
        });
    },
    async truncateContent() {
      if (!this.$refs.rteContent) {
        return;
      }

      const targetContentElement =
        this.isEditMode && !this.isSliderElement
          ? this.$refs.rteContent.querySelector('.scWebEditInput')
          : this.$refs.rteContent.$el;
      const wrapper = targetContentElement.querySelector(`.${this.wrapperClassName}`);
      if (!wrapper) {
        return;
      }
      wrapper.style['-webkit-line-clamp'] = 'unset';
      await this.$nextTick();

      const contentPaddingY =
        parseInt(getComputedStyle(this.$refs.content).paddingTop, 10) +
        parseInt(getComputedStyle(this.$refs.content).paddingBottom, 10);

      let contentHeight = this.$refs.content.childNodes[0].offsetHeight + contentPaddingY;
      let lineClamp = 15;
      const updateLineClamp = async () => {
        wrapper.style['-webkit-line-clamp'] = lineClamp;
        await this.$nextTick();
        lineClamp -= 1;

        contentHeight = this.$refs.content.childNodes[0].offsetHeight + contentPaddingY;

        if (contentHeight <= this.$refs.contentContainer.offsetHeight || lineClamp === 0) {
          return;
        }

        updateLineClamp();
      };

      if (contentHeight > this.$refs.contentContainer.offsetHeight) {
        updateLineClamp();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './teaser-big-image-element-fi';
</style>

<style lang="scss">
@import './teaser-big-image-element-fi-unscoped';
</style>
